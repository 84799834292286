














import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import { Toast } from "vant";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class ShopPhoto extends Vue {
  public id = ""; // 门店id
  public form_list: MyForm.formList[] = [];

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 完成
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.submitRequest(this.$api.getFormData([this.form_list]));
    }
  }
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    data.shop_id = this.id;
    this.$api.request({
      url: "common/shop/up-shop-img",
      data,
      success(res) {
        _this.$api.href("/shop/detail?id=" + _this.id);
        Toast("已提交审核，等待审核中...");
      }
    });
  }

  init() {
    this.id = String(this.$route.query.id || "");
    this.form_list = [
      {
        name: "site_img",
        value: "",
        label: "蜜印摆放位置",
        type: "image",
        required: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);
  }
}
